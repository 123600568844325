import React, { Fragment, useEffect, useState } from "react";
import RouterLink from "./RouterLink";
import MetisMenu from "react-metismenu";
import {
  VendorExchageNav,
  VendorAssessmentsNav,
  AssessorNav,
  BusinessNav,
  ThirdPartyAssessmentRequestsNav,
  LeadAssessorPendingNav,
  BusinessDomainsNav,
  RiderMatrixNav,
  VendorQuestionnaireNav,
  RemediationsNav,
  ClientSupportNav,
  RolesAndPermissionDetails,
  TPRMSupportServicesNav,
  PublicVendorNav,
  ImportLegacyAssessmentsNav,
} from "./NavItems";
import { useLocation } from "react-router-dom";
import { useAuthState } from "../../context/login";
import { Roles } from "../../utils/constants";

const random = () => Math.random().toString().slice(2);

const Nav = () => {
  const location = useLocation();
  const userDetails = useAuthState();

  const [activeMenu, setActiveMenu] = useState({
    thirdPartyAssessmentRequests: random(),
    vendorExchange: random(),
    vendorAssessment: random(),
    thirdPartyAssessmentRequestsPending: random(),
    remediations: random(),
    tprmSupportServices: random(),
    publicVendors: random(),
    importLegacyAssessments: random(),
  });

  useEffect(() => {
    if (
      LeadAssessorPendingNav[0].internalRoutes.some(
        (x) => location.pathname.indexOf(x) > -1
      )
    ) {
      setActiveMenu({
        vendorExchange: random(),
        vendorAssessment: random(),
        thirdPartyAssessmentRequests: random(),
        thirdPartyAssessmentRequestsPending: "active",
        remediations: random(),
        tprmSupportServices: random(),
        importLegacyAssessments: random(),
      });
      if (LeadAssessorPendingNav[0].searchParams === location.search) {
        return;
      }
    }

    if (
      VendorAssessmentsNav[0].internalRoutes.some(
        (x) => location.pathname.indexOf(x) > -1
      )
    ) {
      setActiveMenu({
        vendorExchange: random(),
        vendorAssessment: "active",
        thirdPartyAssessmentRequests: random(),
        thirdPartyAssessmentRequestsPending: random(),
        remediations: random(),
        tprmSupportServices: random(),
        importLegacyAssessments: random(),
      });
    }

    if (
      ThirdPartyAssessmentRequestsNav[0].internalRoutes.some(
        (x) => location.pathname.indexOf(x) > -1
      )
    ) {
      setActiveMenu({
        vendorExchange: random(),
        vendorAssessment: random(),
        thirdPartyAssessmentRequests: "active",
        thirdPartyAssessmentRequestsPending: random(),
        remediations: random(),
        tprmSupportServices: random(),
        importLegacyAssessments: random(),
      });
    }

    if (
      VendorExchageNav[0].internalRoutes.some(
        (x) => location.pathname.indexOf(x) > -1
      )
    ) {
      setActiveMenu({
        vendorExchange: "active",
        vendorAssessment: random(),
        thirdPartyAssessmentRequests: random(),
        thirdPartyAssessmentRequestsPending: random(),
        remediations: random(),
        tprmSupportServices: random(),
        importLegacyAssessments: random(),
      });
    }
  }, [location.pathname]);

  return (
    <Fragment>
      {userDetails && userDetails?.user?.roleDesc === Roles.ADMIN && (
        <>
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={AssessorNav}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={BusinessNav}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{
              marginTop: "10px !important",
            }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={BusinessDomainsNav}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{
              marginTop: "10px !important",
            }}
          />

          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={RiderMatrixNav}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{
              marginTop: "10px !important",
            }}
          />

          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={VendorQuestionnaireNav}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{
              marginTop: "10px !important",
            }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={RolesAndPermissionDetails}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
        </>
      )}

      {userDetails && userDetails?.user?.roleDesc !== Roles.ADMIN && (
        <>
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={VendorExchageNav}
            classNameLink={activeMenu.vendorExchange}
            key={activeMenu.vendorExchange}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={PublicVendorNav}
            classNameLink={activeMenu.publicVendors}
            key={activeMenu.publicVendors}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-global"
            style={{ marginTop: "10px !important" }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={ThirdPartyAssessmentRequestsNav}
            classNameLink={activeMenu.thirdPartyAssessmentRequests}
            key={activeMenu.thirdPartyAssessmentRequests}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={RemediationsNav}
            classNameLink={activeMenu.remediations}
            key={activeMenu.remediations}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={ClientSupportNav}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{
              marginTop: "10px !important",
            }}
          />
        </>
      )}

      {userDetails && userDetails?.user?.roleDesc === Roles.LEADASSESSOR && (
        <>
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={LeadAssessorPendingNav}
            classNameLink={activeMenu.thirdPartyAssessmentRequestsPending}
            key={activeMenu.thirdPartyAssessmentRequestsPending}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={TPRMSupportServicesNav}
            classNameLink={activeMenu.tprmSupportServices}
            key={activeMenu.tprmSupportServices}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
          <MetisMenu
            activeLinkFromLocation
            LinkComponent={RouterLink}
            content={ImportLegacyAssessmentsNav}
            classNameLink={activeMenu.importLegacyAssessments}
            key={activeMenu.importLegacyAssessments}
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
            style={{ marginTop: "10px !important" }}
          />
        </>
      )}
    </Fragment>
  );
};

export default Nav;
