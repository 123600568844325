import React, { useEffect, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getLegacyReportFiles } from "../../services/clientSubmissionService";

const colDefs = [
  {
    field: "fileName",
    headerName: "File Name",
    flex: 2,
  },
  {
    headerName: "View",
    width: 100,
    renderCell: (params) => {
      return (
        <OpenInNewIcon
          onClick={(event) => {
            event.stopPropagation();
            window.open(params.row.signedURL, "_blank");
          }}
        />
      );
    },
  },
];

const SelectReport = ({
  isSelectReportOpen,
  setSelectReportOpen,
  handleSelectReport,
  orgId,
}) => {
  const [fileList, setFileList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");

  const fetchFiles = async () => {
    setNameFilter("");
    const result = await getLegacyReportFiles(orgId);
    setFileList(
      result.map((row) => {
        row.id = Math.random().toString(16).slice(2);
        return row;
      })
    );
  };

  useEffect(() => {
    if (isSelectReportOpen) fetchFiles();
  }, [isSelectReportOpen]);

  const filteredGridRows = useMemo(() => {
    if (!nameFilter) return fileList;
    return fileList.filter((row) =>
      row.fileName.toLowerCase().includes(nameFilter.toLowerCase())
    );
  }, [fileList, nameFilter]);

  return (
    <Modal
      size="lg"
      isOpen={isSelectReportOpen}
      toggle={() => setSelectReportOpen(false)}
    >
      <ModalHeader toggle={() => setSelectReportOpen(false)}>
        {"Select Report"}
      </ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col md={12}>
            <Input
              type="text"
              name="name"
              autocomplete="off"
              value={nameFilter}
              onChange={(e) => {
                setNameFilter(e.target.value);
              }}
              id="name"
              placeholder="Search with File Name"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="assessor-popup-list-grid-container">
              <DataGrid
                sx={{
                  // disable cell selection style
                  ".MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  // pointer cursor on ALL rows
                  "& .MuiDataGrid-row:not(.disabled-row):hover": {
                    cursor: "pointer",
                  },
                }}
                rows={filteredGridRows}
                columns={colDefs}
                hideFooter
                onRowSelectionModelChange={async (newRowSelectionModel) => {
                  if (newRowSelectionModel.length === 0) return;
                  handleSelectReport(
                    fileList.filter(
                      (row) => row.id === newRowSelectionModel[0]
                    )[0]
                  );
                }}
                density="compact"
              />
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SelectReport;
