import axios from "axios";
import AppConfig from "../config";
import { hasValue } from "../utils/commonFns";

const baseURL = `${AppConfig.API_URL}/ClientSubmissions`;
const csLogsBaseURL = `${AppConfig.API_URL}/ClientSubmissionLogs`;

const getClientSubmissions = async (nameFilter, approvalStatus) => {
  try {
    var url = `${baseURL}/for-assessor?`;
    if (hasValue(nameFilter)) url += `name=${nameFilter}`;
    if (hasValue(approvalStatus)) url += `&approvalStatus=${approvalStatus}`;

    let { data } = await axios.get(`${url}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getClientSubmission = async (id) => {
  try {
    let { data } = await axios.get(`${baseURL}/${id}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const updateClientSubmissionRequest = async (request) => {
  try {
    let { data } = await axios.put(`${baseURL}`, request, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const updateClientSubmission = async (request) => {
  try {
    let { data } = await axios.put(`${baseURL}/update-details`, request);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const prepopulateReport = async (clientSubmissionId) => {
  try {
    let { data } = await axios.post(
      `${baseURL}/populate-report/${clientSubmissionId}`
    );
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getClientSubmissionLogs = async (id) => {
  try {
    let { data } = await axios.get(`${csLogsBaseURL}/${id}`);
    if (data.success) {
      return data.result;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const validateLegacyAssessments = async (formData) => {
  try {
    let { data } = await axios.post(
      `${AppConfig.LAMBDA_URL}/ClientSubmissionReport/validate-legacy-assessments`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    if (data) {
      return data;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const importLegacyAssessments = async (requestBody) => {
  try {
    let { data } = await axios.post(
      `${AppConfig.LAMBDA_URL}/ClientSubmissionReport/import-legacy-assessments`,
      requestBody
    );
    if (data) {
      return data;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

const getLegacyReportFiles = async (tenantId) => {
  try {
    let { data } = await axios.get(
      `${AppConfig.LAMBDA_URL}/LegacyReport?tenantId=${tenantId}`
    );
    if (data) {
      return data;
    } else {
      console.log(data.error);
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export {
  getClientSubmissions,
  getClientSubmission,
  updateClientSubmissionRequest,
  updateClientSubmission,
  prepopulateReport,
  getClientSubmissionLogs,
  validateLegacyAssessments,
  importLegacyAssessments,
  getLegacyReportFiles,
};
