import React, { Fragment, useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AppContent from "../AppContent";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import "./style.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getOrgs } from "../../services/businessOrgService";
import { hasValue } from "../../utils/commonFns";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import {
  importLegacyAssessments,
  validateLegacyAssessments,
} from "../../services/clientSubmissionService";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import SelectReport from "./SelectReport";

export default function ImportLegacyAssessments() {
  const [orgData, setOrgData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const fileInputRef = useRef(null);
  const [rows, setRows] = useState([]);

  const [isSelectReportOpen, setSelectReportOpen] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState(null);

  const columns = [
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 200,
    },
    {
      field: "department",
      headerName: "Department",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "completedOn",
      headerName: "Completed On",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {hasValue(params.value)
              ? moment(params.value).format("MM/DD/YYYY")
              : ""}
          </>
        );
      },
    },
    {
      field: "residualRisk",
      headerName: "Residual Risk",
      width: 200,
    },
    {
      field: "nextReviewDate",
      headerName: "Next Review Date",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {hasValue(params.value)
              ? moment(params.value).format("MM/DD/YYYY")
              : ""}
          </>
        );
      },
    },
    {
      field: "controlTestingMatrix",
      headerName: "Control Testing Matrix",
      width: 200,
    },
    {
      field: "cyberRiskScanning",
      headerName: "Cyber Risk Scanning",
      width: 200,
    },
    {
      field: "projectScope",
      headerName: "Project Scope",
      width: 200,
    },
    {
      field: "companyData",
      headerName: "Company Data",
      width: 200,
    },
    {
      field: "customerData",
      headerName: "Customer Data",
      width: 200,
    },
    {
      field: "thirdPartyPOC",
      headerName: "Third Party POC",
      width: 200,
    },
    {
      field: "thirdPartyPOCEmail",
      headerName: "Third Party POC Email",
      width: 200,
    },
    {
      headerName: "Attachment Name",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {hasValue(params.row.attachmentName) ? (
              <>
                <a
                  href={params.row.attachmentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    maxWidth: "90%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.row.attachmentName}
                </a>
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    const updatedRows = rows.map((row) => {
                      if (row.id === params.row.id) {
                        return {
                          ...row,
                          attachmentName: null,
                          attachmentUrl: null,
                        };
                      }
                      return row;
                    });
                    setRows(updatedRows);
                  }}
                />
              </>
            ) : (
              <>
                <Button
                  onClick={(event) => {
                    setSelectReportOpen(true);
                    setSelectedRowID(params.row.id);
                  }}
                >
                  Select Report
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleSelectReport = (selectedFile) => {
    const updatedRows = rows.map((row) => {
      if (row.id === selectedRowID) {
        return {
          ...row,
          attachmentName: selectedFile.fileName,
          attachmentUrl: selectedFile.signedURL,
        };
      }
      return row;
    });
    setRows(updatedRows);
    setSelectReportOpen(false);
    setSelectedRowID(null);
  };

  const fetchData = async () => {
    const data = await getOrgs("");
    if (hasValue(data)) {
      setOrgData(data);
    } else {
      setOrgData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleImport = () => {
    if (!hasValue(selectedOrg?.id)) return;
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    var fileslist = event.target.files;
    const file = event.target.files[0];
    if (!file) return;

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      toast("File Size cannot be greater than 5 Mb", {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    var formData = new FormData();

    for (var index = 0; index < fileslist.length; index++) {
      formData.append("assessmentImportFile", fileslist[index]);
    }
    formData.append("businessOrganizationId", selectedOrg?.id);

    const data = await validateLegacyAssessments(formData);
    if (hasValue(data)) {
      if (data.success) {
        setRows(
          data.result.assessmentRows.map((row) => {
            row.id = Math.random().toString(16).slice(2);
            return row;
          })
        );
      } else if (hasValue(data.result) && hasValue(data.result.errorDetails)) {
        let ToastContent = () => {
          return (
            <div style={{ overflow: "hidden" }}>
              <div
                className="error-assessment-container"
                style={{ maxHeight: 600, overflowY: "auto" }}
              >
                {data.result.errorDetails.length > 0 && (
                  <p>
                    <b>Please fix following in Imported File</b>
                    {data.result.errorDetails.map((error, index) => (
                      <p>
                        <b>
                          {index + 1}.{error.vendorName}
                        </b>
                        {error.errors.map((errorMessage) => (
                          <>
                            <p style={{ marginLeft: "10px" }}>{errorMessage}</p>
                          </>
                        ))}
                      </p>
                    ))}
                  </p>
                )}
              </div>
            </div>
          );
        };
        toast(<ToastContent />, {
          type: toast.TYPE.ERROR,
          autoClose: false,
          closeOnClick: false,
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    event.target.value = "";
  };

  const handleUploadAssessments = async () => {
    if (rows.length <= 0) return;

    const noAttachments = rows.filter(
      (x) => !hasValue(x.attachmentName) || !hasValue(x.attachmentUrl)
    );

    if (noAttachments.length > 0) {
      toast(
        "Please Select Reports for " +
          noAttachments.map((x) => x.vendorName).join(", "),
        {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    const data = await importLegacyAssessments({
      assessmentRows: rows,
      businessOrganizationId: selectedOrg?.id,
    });

    if (hasValue(data) && data.success) {
      toast("Assessments Imported Successfully", {
        type: toast.TYPE.SUCCESS,
        position: toast.POSITION.TOP_CENTER,
      });
      setRows([]);
    } else {
      toast(data.error, {
        type: toast.TYPE.ERROR,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle
                heading="Import Legacy Assessments"
                BreadcrumbComp={
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <a href="#">Home</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      Import Legacy Assessments
                    </BreadcrumbItem>
                  </Breadcrumb>
                }
                PageTitleActions={<></>}
              />
              <Card className="main-card mb-3 import-legacy-assessements-cotainer">
                <CardBody className="card-body">
                  <Row xs={12}>
                    <Col style={{ display: "flex", gap: "16px" }}>
                      <Autocomplete
                        disablePortal
                        size="small"
                        options={orgData}
                        getOptionLabel={(option) => option.organizationName}
                        sx={{ width: 300 }}
                        onChange={(e, value) => setSelectedOrg(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Business Organization"
                            variant="filled"
                          />
                        )}
                      />
                      <Button
                        color="primary"
                        className="import-btn"
                        onClick={handleImport}
                        disabled={!hasValue(selectedOrg?.id)}
                      >
                        Preview Assessments
                      </Button>
                      <input
                        name="uploadIconFile"
                        id="uploadIconFile"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        type="file"
                        accept=".csv,.xls,.xlsx"
                        onChange={handleFileChange}
                      />
                      <Button
                        color="primary"
                        className="upload-btn"
                        onClick={handleUploadAssessments}
                        disabled={rows.length <= 0}
                      >
                        Upload Assessments
                      </Button>
                    </Col>
                  </Row>
                  {rows.length > 0 && (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      style={{ maxHeight: "calc(100vh - 264px)" }}
                      hideFooter
                    />
                  )}
                </CardBody>
              </Card>
              <SelectReport
                isSelectReportOpen={isSelectReportOpen}
                setSelectReportOpen={setSelectReportOpen}
                handleSelectReport={handleSelectReport}
                orgId={selectedOrg?.id}
              />
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
